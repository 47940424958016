interface FotobookProps {
  fotobookElement: HTMLElement | HTMLAnchorElement
  siteRoot: string
  fotos: string[]
}

import { BREAKPOINTS, enterViewport, preloadImage } from '../helpers'

class Fotobook {
  fotoWrapper: HTMLElement | null = null;
  fotobookElm: HTMLElement | HTMLAnchorElement;
  siteRoot: string;
  fotos: string[];
  nrOfFotos: number;
  innerWidth: number; 
  imageElm: HTMLImageElement[];
  mainModal: HTMLDivElement | null = null;
  fotoCollection: HTMLElement | null = null;
  closeButton: HTMLElement | null = null;
  biggerThenMD: boolean;
  isDirty: boolean = false;
  detailModal: HTMLElement | null = null;
  detailFoto: HTMLImageElement | null = null;
  currentFotoIndex: number = 0;
  storedIndex: number = 0;
  browseLeftButton: HTMLElement | null = null;
  browseRightButton: HTMLElement | null = null;
  detailFotoSlider: HTMLElement | null = null;
  counter: HTMLElement | null = null;
  detailIsOpen: boolean = false;
  
  constructor(options: FotobookProps) {
    this.fotobookElm = options.fotobookElement;
    this.siteRoot = options.siteRoot;
    this.fotos = options.fotos;
    this.imageElm = [];
    this.innerWidth = window.innerWidth;
    
    this.nrOfFotos = this.fotos.length;
    this.fotobookElm.style.position = 'relative';
    this.createModal();
    this.createMagnifyingGlassButton();
    this.createDetailModal();

    this.openModal = this.openModal.bind(this);
    this.closeModal = this.closeModal.bind(this);
    this.openDetailModal = this.openDetailModal.bind(this);
    this.handleBrouwseButtonsStatus = this.handleBrouwseButtonsStatus.bind(this);
    this.switchDetailFoto = this.switchDetailFoto.bind(this);
    this.closeDetailModal = this.closeDetailModal.bind(this);
    this.resizeFotos = this.resizeFotos.bind(this);
    this.keyBoardBrowse = this.keyBoardBrowse.bind(this);

    this.waitForScrollEnd();

  }

  createMagnifyingGlassButton() {
    const magnifyingGlassButton = document.createElement('button');
    magnifyingGlassButton.classList.add('magnifyingGlassButton');
    magnifyingGlassButton.innerHTML = '<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="1.75rem"><path d="M9.5,3A6.5,6.5 0 0,1 16,9.5C16,11.11 15.41,12.59 14.44,13.73L14.71,14H15.5L20.5,19L19,20.5L14,15.5V14.71L13.73,14.44C12.59,15.41 11.11,16 9.5,16A6.5,6.5 0 0,1 3,9.5A6.5,6.5 0 0,1 9.5,3M9.5,5C7,5 5,7 5,9.5C5,12 7,14 9.5,14C12,14 14,12 14,9.5C14,7 12,5 9.5,5Z" fill="currentColor"/></svg>';
    this.fotobookElm.addEventListener('click', () => this.openModal());
    this.fotobookElm.appendChild(magnifyingGlassButton);
  }

  createModal() {
    this.mainModal = document.createElement('div');
    this.mainModal.classList.add('modal');
    this.createFotoCollection();
    document.body.appendChild(this.mainModal);
  }
  
  openModal() {
    if(!this.isDirty) {
      this.placeFotosInCollection();
      this.isDirty = true;
    }
    this.resizeFotos();
    this.setEventListeners();
    this.mainModal.classList.add('modalOpen');
    document.body.style.overflow = 'hidden';
  }
  
  closeModal() {
    this.mainModal.classList.remove('modalOpen');
    document.body.style.overflow = 'auto';
    this.unsetEventListeners();
  }
  
  createFotoCollection() {
    this.fotoCollection = document.createElement('div');
    this.fotoCollection.classList.add('fotoCollection');
    this.createCloseButton()
    this.mainModal.appendChild(this.fotoCollection);
  }
  
  createCloseButton() {
    this.closeButton = document.createElement('button');
    this.closeButton.innerHTML = '<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="2rem"><path d="M19,6.41L17.59,5L12,10.59L6.41,5L5,6.41L10.59,12L5,17.59L6.41,19L12,13.41L17.59,19L19,17.59L13.41,12L19,6.41Z" fill="currentColor"/></svg>'
    this.closeButton.classList.add('closeButton');
    this.fotoCollection.appendChild(this.closeButton);
  }

  placeFotosInCollection() {
    this.fotoWrapper = document.createElement('div');
    this.fotoWrapper.classList.add('fotoWrapper');
    this.fotos.forEach((foto, index) => {
      const _foto = this.createFotoElement(foto, index);
      _foto.style.width = index % 5 === 0 || (index - 1) % 5 === 0 ? 'calc(50% - 1rem)' : 'calc(33.333% - 1rem)';
      this.fotoWrapper.appendChild(_foto);
    });
    this.fotoCollection.appendChild(this.fotoWrapper);
  }
  
  createFotoElement(foto: string, index: number): HTMLElement {
    const fotoElement = document.createElement('img');
    fotoElement.classList.add('fotoElement');
    fotoElement.classList.add('foto');
    enterViewport(fotoElement, {
      root: this.fotoWrapper,
      threshold: 0.5,
      rootMargin: '0px',
      imgSrc: `${this.siteRoot}assets/images/individueel/${foto}`
    }, 'vertical');
    fotoElement.src = preloadImage();
    fotoElement.addEventListener('click', () => this.openDetailModal(index));
    return fotoElement;
  }
  
  resizeFotos() {
    this.innerWidth = window.innerWidth;
    this.resetSliderPosition();
    if(window.matchMedia('(max-width: 768px)').matches) {
      Array.from(document.querySelectorAll('.foto')).forEach((foto) => {
        (foto as HTMLElement).style.width = '100%';
      })
    } else {
      Array.from(document.querySelectorAll('.foto')).forEach((foto, index) => {
        (foto as HTMLElement).style.width = index % 5 === 0 || (index - 1) % 5 === 0 ? 'calc(50% - 1rem)' : 'calc(33.333% - 1rem)';
      })
    }
    return;
  }

  // Detail Modal

  createDetailModal() {
    this.detailModal = document.createElement('div');
    this.detailModal.classList.add('detailmodal');
    const detailCloseButton = this.closeButton.cloneNode(true);
    detailCloseButton.addEventListener('click', () => { 
      this.closeDetailModal() 
    });
    this.detailModal.appendChild(detailCloseButton);
    this.detailModal.appendChild(this.createDetailFotoSlider());
    document.body.appendChild(this.detailModal);
  }

  openDetailModal(index: number) {
    this.detailIsOpen = true;
    this.currentFotoIndex = index;
    this.storedIndex = index;
    this.handleBrouwseButtonsStatus();
    this.handleSliderPosition();
    this.imageElm[this.currentFotoIndex].src = `${this.siteRoot}assets/images/individueel-hi-res/${this.fotos[this.currentFotoIndex]}`;
    this.detailModal.classList.add('detailmodalOpen');
  }
  
  handleSliderPosition() {
    if(this.innerWidth > BREAKPOINTS.MD) {
      this.detailFotoSlider.scrollTo(0, 0);
      this.detailFotoSlider.style.transform = `translateX(${-1 * this.innerWidth * this.currentFotoIndex}px)`;
    } else {
      this.detailFotoSlider.style.transform = 'translateX(0px)';
      this.detailFotoSlider.scrollTo({
        top: 0,
        left: (this.innerWidth * this.currentFotoIndex),
        behavior: 'smooth'
      });
    }
  }

  resetSliderPosition() {
    this.currentFotoIndex = this.storedIndex;
    this.handleSliderPosition();
  }

  closeDetailModal() {
    this.detailIsOpen = false;
    this.resetSliderPosition();
    this.detailModal.classList.remove('detailmodalOpen')
  }

  createDetailFotoSlider() {
    this.detailFotoSlider = document.createElement('div');
    this.detailFotoSlider.classList.add('detailFotoSlider');
    this.fotos.forEach((foto) => {
      const _foto = document.createElement('div');
      _foto.classList.add('fotoDetailElement');
      const _img = document.createElement('img');
      _img.classList.add('fotoDetail');
      enterViewport(_img, {
        root: this.detailModal,
        threshold: 1.0,
        rootMargin: '0px',
        imgSrc: `${this.siteRoot}assets/images/individueel-hi-res/${foto}`
      }, 'horizontal');
      _img.src = preloadImage();
      this.imageElm.push(_img);
      _foto.appendChild(_img);
      this.detailFotoSlider.appendChild(_foto);
    });
    this.createBrowseButtons('left')
    this.createBrowseButtons('right')
    this.createCounter()
    return this.detailFotoSlider;
  }

  createBrowseButtons(direction: 'left' | 'right') {
    if(direction === 'right') {
      this.browseRightButton = document.createElement('button');
      this.browseRightButton.innerHTML = '<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="2rem"><path d="M8.59,16.58L13.17,12L8.59,7.41L10,6L16,12L10,18L8.59,16.58Z" fill="currentColor" /></svg>'
      this.browseRightButton.classList.add('browseButton');
      this.browseRightButton.style.right = '0.75rem';
      this.browseRightButton.addEventListener('click', () => this.switchDetailFoto(1));
      this.detailModal.appendChild(this.browseRightButton);
    } else {
      this.browseLeftButton = document.createElement('button');
      this.browseLeftButton.innerHTML = '<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="2rem"><path d="M15.41,16.58L10.83,12L15.41,7.41L14,6L8,12L14,18L15.41,16.58Z" fill="currentColor" /></svg>'
      this.browseLeftButton.classList.add('browseButton');
      this.browseLeftButton.style.left = '0.75rem';
      this.browseLeftButton.addEventListener('click', () => this.switchDetailFoto(-1));
      this.detailModal.appendChild(this.browseLeftButton);
    }
  }

  createCounter() {
    this.counter = document.createElement('div');
    this.counter.classList.add('counter');
    this.updateCounter();
    this.detailModal.appendChild(this.counter);
  }

  updateCounter() {
    this.counter.innerHTML = `foto ${this.currentFotoIndex + 1} van ${this.nrOfFotos}`;
  }

  switchDetailFoto(direction: number) {
    if(this.currentFotoIndex + direction !== -1 && this.currentFotoIndex + direction < this.nrOfFotos) {
      this.currentFotoIndex = this.currentFotoIndex + direction;
      this.storedIndex = this.currentFotoIndex;
      this.detailFotoSlider.style.transform = `translateX(${-1 * this.innerWidth * this.currentFotoIndex}px)`;
    }
    this.handleBrouwseButtonsStatus();
  }
  
  handleBrouwseButtonsStatus() {
    this.currentFotoIndex === 0 ? this.browseLeftButton.style.visibility = 'hidden' : this.browseLeftButton.style.visibility = 'visible';
    this.currentFotoIndex === this.nrOfFotos - 1 ? this.browseRightButton.style.visibility = 'hidden' : this.browseRightButton.style.visibility = 'visible';
    this.updateCounter();
  }

  waitForScrollEnd() {
    let isScrolling: number;
    this.detailFotoSlider.addEventListener('scroll', () => {
      window.clearTimeout(isScrolling);
      isScrolling = window.setTimeout(() => {
        this.currentFotoIndex = Math.round(this.detailFotoSlider.scrollLeft / this.innerWidth);
        this.storedIndex = this.currentFotoIndex;
        this.handleBrouwseButtonsStatus();
        this.updateCounter();
      }, 66);
    });
  }

  keyBoardBrowse(event: KeyboardEvent) {
    if(event.key === 'Escape') {
      this.detailIsOpen ? this.closeDetailModal() : this.closeModal();
    } 
    if (event.key === 'ArrowRight') {
      this.switchDetailFoto(1)
    }
    if (event.key === 'ArrowLeft') {
      this.switchDetailFoto(-1)
    }
  }

  setEventListeners() {
    this.mainModal.addEventListener('click', this.closeModal);
    this.closeButton.addEventListener('click', this.closeModal);
    this.fotoCollection.addEventListener('click', (event) => event.stopPropagation());
    window.addEventListener('keydown', this.keyBoardBrowse);
    window.addEventListener('resize', this.resizeFotos);
  }

  unsetEventListeners() {
    this.mainModal.removeEventListener('click', this.closeModal);
    this.closeButton.removeEventListener('click', this.closeModal);
    this.fotoCollection.removeEventListener('click', (event) => event.stopPropagation());
    window.removeEventListener('keydown', this.keyBoardBrowse);
    window.removeEventListener('resize', this.resizeFotos);
  }


}

export default Fotobook