import {
  BREAKPOINTS,
  BreakpointState,
  Breakpoints,
  debounce,
  getViewportWidth,
} from './helpers'

import Slider from './slider/slider';
import Fotobook from './fotobook/fotobook';
import DatePicker from './datepicker/datepicker';
import Accordion from './accordion/accordion';

/* TS TYPES */
declare var siteRoot: string
type TnrOfSlidesPerBeakpoint = { [key: string]: number }

/* GLOBAL VARIABLES */
declare var grecaptcha: any;
let screenWidth: Breakpoints;
let biggerThen: BreakpointState = getViewportWidth().biggerThan;
let themeSwitch: HTMLInputElement;
let currentTheme: "light" | "dark";
let header: HTMLElement;
let lastScrollPosition = 0;
const headerHeights: Record<string, number> = {
  'XXS': 55,
  'XS': 55,
  'SM': 60,
  'MD': 80,
  'LG': 100,
  'XL': 100,
  'XXL': 100
}
let headerLogo: HTMLImageElement;
let hamburger: HTMLButtonElement;
let mobileMenu: HTMLElement;
let sliderElements: NodeListOf<HTMLElement>;
const sliders: Slider[] = [];
const prefersDarkScheme = window.matchMedia("(prefers-color-scheme: dark)");
const prefersReducedMotion = window.matchMedia(
  "(prefers-reduced-motion: reduce)"
);
let scrollers: NodeListOf<HTMLDivElement>;

declare global {
  interface Window {
    roomSurchargesString: string;
  }
}
let fotobookElements: NodeListOf<HTMLElement>;
let listSorter: HTMLSelectElement
const fotobooks: Fotobook[] = [];
let datePickerElements: NodeListOf<HTMLInputElement>;
const datePickers: DatePicker[] = [];
let accordionElements: Accordion[] = [];

/* LAZY LOAD MODULE */
import lozad from 'lozad'
import { debug } from 'webpack';

const observer = lozad('.lozad', {
  rootMargin: '10px 0px',
  threshold: 0.1,
  loaded: function (el: HTMLImageElement) {
    el.classList.add('loaded')
  },
})
observer.observe()

/* ONLOAD */
const pageInit = (): void => {
  window.onresize = () => resizeDebounce();
  window.onscroll = () => scrollHandler();

  listSorter = document.querySelector('#listSorter');
  
  if(listSorter) {
    listSorter.addEventListener('change', () => {
      const value = listSorter.value;
      if(value === 'default') {
        location.assign(`${siteRoot}motorreizen/`)
      } else {
        location.assign(`${siteRoot}motorreizen/?sort=${value}`)
      }
    })
  }
  
  currentTheme = localStorage.getItem("theme") as "light" | "dark";

  header =  document.querySelector(".header__container");

  headerLogo = document.querySelector("#headerLogo");

  themeSwitch = document.querySelector("#themeSwitch");

  if (themeSwitch) {
    themeSwitch.addEventListener("click", switchTheme);
  }

  setThemeOnLoad();

  hamburger = document.querySelector("#hamburger");
  mobileMenu = document.querySelector("#mobile-menu");

  if (hamburger)
    hamburger.addEventListener(
      "click",
      () => {
        if (
          !mobileMenu ||
          mobileMenu.getAttribute("aria-expanded") === "false"
        ) {
          hamburger.classList.toggle("menu__hamburger--open");
          mobileMenu.classList.toggle("menu__mobile--open");
        } else {
          mobileMenu.setAttribute("aria-expanded", "false");
          mobileMenu.classList.remove("menu__mobile--open");
        }
      },
      false
    );

  scrollers = document.querySelectorAll(".scroller");

  if (!prefersReducedMotion.matches) {
    addAnimation();
  }

  sliderElements = document.querySelectorAll('.slider') as NodeListOf<HTMLElement>

  if(sliderElements.length > 0) {
    Array.from(sliderElements).forEach((slider) => {
      const sliderInstance = setSlider(slider);
      sliders.push(sliderInstance);
    })
  }

  fotobookElements = document.querySelectorAll('.fotobook') as NodeListOf<HTMLElement>

  if(fotobookElements.length > 0) {
    Array.from(fotobookElements).forEach((fotobook) => {
      const fotobookInstance = setFotobook(fotobook);
      fotobooks.push(fotobookInstance);
    })
  }

  datePickerElements = document.querySelectorAll('.datepicker') as NodeListOf<HTMLInputElement>

  if(datePickerElements.length > 0) {
    Array.from(datePickerElements).forEach((datepicker) => {
      const datePickerInstance = setDatePicker(datepicker);
      datePickers.push(datePickerInstance);
    })
  }

  const birthDateFields = document.querySelectorAll('.js-birthdate') as NodeListOf<HTMLInputElement>

  if(birthDateFields.length > 0) {
    Array.from(birthDateFields).forEach((bdField) => {
      bdField.addEventListener('keypress', (evt) => {
        if (!/^\d$/.test(evt.key)) {
          evt.preventDefault();
        }

        var len = bdField.value.length;

        if(len !== 1 && len !== 3) {
          if(evt.code === 'Minus') {
            evt.preventDefault();
          }
        }

        if(len === 2) {
          bdField.value += '-';
        }

        if(len === 5) {
          bdField.value += '-';
        }
        
      })
    })
  }
  
  accordionElements = Array.from(document.querySelectorAll('.accordion')).map((accordion) => {
    return new Accordion({
      element: accordion as HTMLElement
    })
  })

  const contactForm = document.querySelector('#contactForm')
  
  if(contactForm) {
    setFocusEvents(contactForm as HTMLFormElement);
    contactForm.addEventListener('submit', (event) => {
      event.preventDefault();
      handleForm(contactForm as HTMLFormElement)
    });
  };

  const bookingForm = document.querySelector('#bookingForm')

  if(bookingForm) {
    setNrOfPersonListeners(bookingForm as HTMLFormElement);
    bookingForm.addEventListener('submit', (event) => {
      event.preventDefault();
      handleForm(bookingForm as HTMLFormElement)
    });
  }

  const reviewForm = document.querySelector('#reviewForm')

  if(reviewForm) {
    reviewForm.addEventListener('submit', (event) => {
      event.preventDefault();
      handleForm(reviewForm as HTMLFormElement)
    });
  }
  
  resizeOnLoad();
};

/* SWITCH THEME */
const setThemeOnLoad = () => {
  let theme: "light" | "dark";

  if (!currentTheme && prefersDarkScheme.matches) {
    document.body.classList.add("dark-mode");
    theme = "dark";
  } else {
    if (currentTheme === "dark") {
      document.body.classList.add("dark-mode");
      theme = "dark";
    } else {
      document.body.classList.add("light-mode");
      theme = "light";
    }
  }

  localStorage.setItem("theme", theme);
  headerLogo.src = `${siteRoot}assets/images/moormotor-logo-${theme}.svg`;
  themeSwitch.checked = theme === "dark";
};

const switchTheme = () => {
  let theme: "light" | "dark";
  if (prefersDarkScheme.matches) {
    document.body.classList.toggle("light-mode");
    theme = document.body.classList.contains("light-mode") ? "light" : "dark";
  } else {
    document.body.classList.remove("light-mode");
    document.body.classList.toggle("dark-mode");
    theme = document.body.classList.contains("dark-mode") ? "dark" : "light";
  }
  headerLogo.src = `${siteRoot}assets/images/moormotor-logo-${theme}.svg`;
  localStorage.setItem("theme", theme);
};

const addAnimation = () => {
  Array.from(scrollers).forEach((scroller) => {
    scroller.setAttribute("data-animated", "true");
    const strip = scroller.querySelector(".scrollerstrip");

    strip.setAttribute("data-animated", "true");

    const items = Array.from(strip.children);
    items.forEach((item) => {
      const duplicateeItem = item.cloneNode(true) as HTMLLIElement;
      duplicateeItem.setAttribute("aria-hidden", "true");
      strip.appendChild(duplicateeItem);
    });
  });
};

const setSlider = (sliderElement: HTMLElement): Slider => {
  return new Slider({
    sliderElement: sliderElement as HTMLElement,
    previousSlideButton: sliderElement.parentElement.querySelector('.slider-browse-previous'),
    nextSlideButton: sliderElement.parentElement.querySelector('.slider-browse-next'),
    sliderCounter: sliderElement.parentElement.querySelector('.slider-counter'),
    sliderNavigation: sliderElement.parentElement.querySelector('.slider__browse-container'),
  })
}

const setFotobook = (fotobookElement: HTMLElement | HTMLAnchorElement): Fotobook => {
  return new Fotobook({
    fotobookElement: fotobookElement as HTMLElement | HTMLAnchorElement,
    siteRoot: siteRoot,
    fotos: fotobookElement.getAttribute('data-fotos').split(','),
  })
}

const setDatePicker = (datePickerElement: HTMLInputElement): DatePicker => {
  return new DatePicker({
    element: datePickerElement as HTMLInputElement,
    startDate: new Date(),
  })
}

/* BINDING EVENTS */
if (document.addEventListener)
  document.addEventListener("DOMContentLoaded", pageInit, false);

/* HELPER FUNCTIONS */
const resizeDebounce = debounce(resizeHandler, 25)
const resizeOnLoad = debounce(resizeHandler, 0)

function setNrOfSliderSlides(
  slider: Slider,
  nrOfSlidesPerBeakpoint: TnrOfSlidesPerBeakpoint
) {
  biggerThen = getViewportWidth().biggerThan
  let nrOfSlides = nrOfSlidesPerBeakpoint.XXS

  for (const bPoint in nrOfSlidesPerBeakpoint) {
    if (biggerThen[bPoint as keyof typeof BREAKPOINTS])
      nrOfSlides = nrOfSlidesPerBeakpoint[bPoint]
  }

  slider.setNrOfVisibleSlides(nrOfSlides)

  if (biggerThen.LG) {
    slider.setSliderCountText()
  } else {
    slider.setNrOfVisibleSlides(nrOfSlides)
  }
}

function resizeHandler() {
  screenWidth = getViewportWidth().mediaType;
  biggerThen = getViewportWidth().biggerThan;

  if (sliders.length > 0) {
    sliders.forEach((slider) => {
      setNrOfSliderSlides(slider, {
        XXS: 1.2,
        XS: 1.2,
        SM: 1.3,
        MD: 2.2,
        LG: 3,
        XL: 4,
        XXL: 4,
      })
    })
  }
}

function scrollHandler() {
  const currentPosition = document.documentElement.scrollTop || document.body.scrollTop;
  
  if(currentPosition === 0) {
    header.classList.remove('header--unpinned')
    header.classList.remove('header--pinned')
    header.classList.remove('header--animation')
    header.classList.add('header--unfixed')
    header.classList.add('header--no-animation')
  }
  if(currentPosition > lastScrollPosition && currentPosition > headerHeights[screenWidth]) {
    header.classList.remove('header--unfixed')
    header.classList.add('header--unpinned')
  }
  if(currentPosition < lastScrollPosition && currentPosition > headerHeights[screenWidth]) {
    header.classList.remove('header--fixed')
    header.classList.remove('header--no-animation')
    header.classList.remove('header--unpinned')
    header.classList.add('header--animation')
    header.classList.add('header--pinned')
  }

  lastScrollPosition = currentPosition;
}

const isRequired = (value: string): boolean => {
  const regex = /^\s*$/;
  return !regex.test(value);
};

const isEmail = (value: string): boolean => {
  const regex =
    /^((([a-z]|\d|[!#\$%&'\*\+\-\/=\?\^_`{\|}~]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])+(\.([a-z]|\d|[!#\$%&'\*\+\-\/=\?\^_`{\|}~]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])+)*)|((\x22)((((\x20|\x09)*(\x0d\x0a))?(\x20|\x09)+)?(([\x01-\x08\x0b\x0c\x0e-\x1f\x7f]|\x21|[\x23-\x5b]|[\x5d-\x7e]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(\\([\x01-\x09\x0b\x0c\x0d-\x7f]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF]))))*(((\x20|\x09)*(\x0d\x0a))?(\x20|\x09)+)?(\x22)))@((([a-z]|\d|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(([a-z]|\d|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])([a-z]|\d|-|\.|_|~|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])*([a-z]|\d|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])))\.)+(([a-z]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(([a-z]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])([a-z]|\d|-|\.|_|~|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])*([a-z]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])))\.?$/i;
  return regex.test(value);
};

const isPhone = (value: string): boolean => {
  const regex =
    /^(\+|00|0)(31\s?)?(6[\s-]?[1-9][0-9]{7}|[1-9][0-9][\s-]?[1-9][0-9]{6}|[1-9][0-9]{2}[\s-]?[1-9][0-9]{5})/;
  return regex.test(value);
};

const isMobile = (value: string): boolean => {
  const regex =
    /^(\+|00|0)(31\s?)?(6[\s-]?[1-9][0-9]{7}|[1-9][0-9][\s-]?[1-9][0-9]{6}|[1-9][0-9]{2}[\s-]?[1-9][0-9]{5})/;
  return regex.test(value);
};

const isZip = (value: string): boolean => {
  const regex = /^[1-9][0-9]{3}\s?[a-zA-Z]{2}$/;
  return regex.test(value);
};

const isNumber = (value: string): boolean => {
  const regex = /^-?\d+(\.\d+)?$/;
  return regex.test(value);
};

const notZero = (value: string): boolean => {
  return parseInt(value) > 0 || value != "";
};

const isDate = (value: string): boolean => {
  const regex = /^(0[1-9]|[12][0-9]|3[01])-(0[1-9]|1[0-2])-\d{4}$/;
    return regex.test(value);
};

const checkInputField = (element: HTMLFormElement, validationType: string): boolean => {
  const getRules = /validate\[(.*)\]/.exec(validationType);
  getRules.shift(0)
  switch(getRules[0]) {
    case 'required':
      return isRequired(element.value);
    case 'email':
      return isEmail(element.value);
    case 'phone':
      return isPhone(element.value);
    case 'mobile':
      return isMobile(element.value);
    case 'zipcode':
      return isZip(element.value);
    case 'notzero':
      return notZero(element.value);
    case 'date':
      return isDate(element.value);
    case 'number':
      return isNumber(element.value);
  }
  return false;
}

const checkCheckBoxField = (element: HTMLFormElement, validationType: string): boolean => {
  return true;
}

const checkRadioField = (element: HTMLFormElement, validationType: string): boolean => {
  return true;
}

const checkTextarea = (element: HTMLFormElement, validationType: string): boolean => {
  const getRules = /validate\[(.*)\]/.exec(validationType);
  getRules.shift(0);
  switch(getRules[0]) {
    case 'required':
      return isRequired(element.value);
  }
  return false;
}

const resetErrorFields = () => {
  document.querySelectorAll('.form__error').forEach((errorField) => {
    (errorField as HTMLElement).style.display = 'none'
  })
}

const setFocusEvents = (formToCheck: HTMLFormElement) => {
  formToCheck.querySelectorAll('[data-validation]').forEach((element: HTMLFormElement) => {
    element.addEventListener('focus', () => {
      (element.parentNode.querySelector('.form__error') as HTMLElement).style.display = 'none'
    })
  })
}

const handleForm = (formToCheck: HTMLFormElement) => {
  const inputElementToken = document.createElement("input");
  inputElementToken.type = "hidden";

  resetErrorFields();
  let isValid = true;
  let firstInvalidElement: HTMLFormElement;
  
  grecaptcha.ready(function() {
    grecaptcha.execute('6LfoLmojAAAAAPtsDG6_m3P5S7OKCS-u-aruizAy', {action: formToCheck.id}).then(function(token: string) {
    formToCheck.querySelectorAll('[data-validation]').forEach((element: HTMLFormElement, index: number) => {
      inputElementToken.name = "token";
      inputElementToken.value = token;
      formToCheck.prepend(inputElementToken);

      const inputElementAction = document.createElement("input");
      inputElementAction.type = "hidden";
      inputElementAction.name = "action";
      inputElementAction.value = formToCheck.id;
      formToCheck.prepend(inputElementAction);
      const errorField = element.parentNode.querySelector('.form__error') as HTMLElement;
      
      switch(element.type) {
        case 'text':
          if(!checkInputField(element, element.getAttribute('data-validation'))) {
            errorField.style.display = 'block'
            isValid = false;
            firstInvalidElement = firstInvalidElement ? firstInvalidElement : element;
            element.addEventListener('focus', () => errorField.style.display = 'none')
          };
          break;
        case 'select-one':
          if(!checkInputField(element, element.getAttribute('data-validation'))) {
            errorField.style.display = 'block'
            isValid = false;
            firstInvalidElement = firstInvalidElement ? firstInvalidElement : element;
            element.addEventListener('focus', () => errorField.style.display = 'none')
          };
          break;
        case 'checkbox':
            if(!checkCheckBoxField(element, element.getAttribute('data-validation'))) {
              errorField.style.display = 'block'
              isValid = false;
              firstInvalidElement = firstInvalidElement ? firstInvalidElement : element;
              element.addEventListener('focus', () => errorField.style.display = 'none')
            };
          break;
        case 'radio':
            if(!checkRadioField(element, element.getAttribute('data-validation'))) {
              errorField.style.display = 'block'
              isValid = false;
              firstInvalidElement = firstInvalidElement ? firstInvalidElement : element;
              element.addEventListener('focus', () => errorField.style.display = 'none')
            };
          break;
        case 'textarea':
          if(!checkTextarea(element, element.getAttribute('data-validation'))) {
              errorField.style.display = 'block'
              isValid = false;
              firstInvalidElement = firstInvalidElement ? firstInvalidElement : element;
              element.addEventListener('focus', () => errorField.style.display = 'none')
            };
          break;
        }
      })

      if(isValid) {
        formToCheck.submit();
      } else {
        firstInvalidElement.scrollIntoView({behavior: "smooth", block: "center", inline: "nearest"});
      }
      
    });
  });
}

//BOOKING FORM

const blockInvalidChar = (event: KeyboardEvent) => ['e', 'E', '+', '-'].includes(event.key) && event.preventDefault();

const setNrOfPersonListeners = (bookingForm: HTMLFormElement) => {
  const nrOfPersons = bookingForm.querySelector('#members') as HTMLInputElement;
  const days = bookingForm.querySelector('#days') as HTMLDivElement;
  nrOfPersons.addEventListener('keydown', (event) => blockInvalidChar(event));
  const membersPannel = document.querySelector('#membersPannel') as HTMLDivElement;
  const membersList = document.querySelector('#memberList') as HTMLDivElement;
  nrOfPersons.addEventListener('keyup', (event) => {
    const roomSurcharges = window.roomSurchargesString !== '' ? window.roomSurchargesString.split('|') : [];
    const value = parseInt((event.target as HTMLInputElement).value);
    if(value > 1) {
      membersPannel.classList.remove('visability__hidden');
    } else {
      membersPannel.classList.add('visability__hidden');
    }
    fillMemberList(value, membersList, roomSurcharges);
  })
  days.addEventListener('change', () => {
    setTimeout(() => {
      const roomSurcharges = window.roomSurchargesString !== '' ? window.roomSurchargesString.split('|') : [];
      const value = parseInt(nrOfPersons.value);
      fillMemberList(value, membersList, roomSurcharges);
    }, 0);
  });
}

const fillMemberList = (nrOfMembers: number, memberList: HTMLDivElement, roomSurcharges: string[]) => {
  memberList.innerHTML = '';
  for(let memberNumber = 1; memberNumber < nrOfMembers; memberNumber++) {
    memberList.appendChild(createMemberForm(memberNumber, roomSurcharges));
  }
}

const createRoomSurchargeSelect = (memberNumber: number, roomSurcharges: string[]) => {
  return `
  <div class="form__select mb-100">
      <label for="roomsurcharge_${memberNumber}">Kamersoort:</label>
      <select id="roomsurcharge_${memberNumber}" name="roomsurcharge_${memberNumber}">
        <option value="">Kies een kamersoort en/of upgrade</option>
        ${roomSurcharges.map((roomSurcharge) => {
          return `<option value="${roomSurcharge}">${roomSurcharge}</option>`
        }).join('')}
      </select>
      <div class="form__error">Wat voor kamer wil deze medereiziger?.</div>
    </div>`;
}

const createMemberForm = (memberNumber: number, roomSurcharges: string[]) => {
  const memberForm = document.createElement('div');
  memberForm.classList.add('member-form');
  memberForm.innerHTML = `
    <h4 class="h4">Persoonsgegevens medereiziger (${memberNumber})</h4>
    <div class="form__row">
      <div class="form__select mb-050">
        <label for="gender_${memberNumber}">Hoe identificeer je de medereiziger?:</label>
        <select id="gender_${memberNumber}" name="gender_${memberNumber}">
            <option value="">Maak een keuze</option>
            <option value="Man">Man</option>
            <option value="Vrouw">Vrouw</option>
          </select>
        </div>
      </div>
    </div>
    <div class="form__row">
      <div class="form__cell--066 form__input">
        <label for="surname_${memberNumber}">Voornaam:<span>*</span></label>
        <input type="text" id="surname_${memberNumber}" name="surname_${memberNumber}" data-validation="validate[required]" />
        <div class="form__error">Wat is de voornaam van deze medereiziger?.</div>
      </div>
      <div class="form__cell--033 form__input">
        <label for="insert_${memberNumber}">Tussenvoegsel:</label>
        <input type="text" id="insert_${memberNumber}" name="insert_${memberNumber}" />
      </div>
    </div>
    <div class="form__input">
      <label for="name_${memberNumber}">Achternaam:<span>*</span></label>
      <input type="text" id="name_${memberNumber}" name="name_${memberNumber}" data-validation="validate[required]" />
      <div class="form__error">Wat is de achternaam van deze medereiziger?.</div>
    </div>
    <div class="form__row mb-150">
      <div class="form__cell form__input">
        <label for="email_${memberNumber}">E-mail:<span>*</span></label>
        <input type="text" id="email_${memberNumber}" name="email_${memberNumber}" data-validation="validate[email]" />
        <div class="form__error">Wat is het e-mail adres van deze medereiziger?.</div>
      </div>
      <div class="form__cell form__checkbox mt-275">
        <input id="isduo_${memberNumber}" type="checkbox" value="Ik ben duopasagier" name="isduo_${memberNumber}">
        <label class="" for="isduo_${memberNumber}">Ik ben duopasagier</label>
      </div>
    </div>
    ${roomSurcharges.length > 0 ? createRoomSurchargeSelect(memberNumber, roomSurcharges) : ''}
    <div class="decorator__hline"></div>
  `
  return memberForm;

}
