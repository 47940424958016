interface Props {
  sliderElement: HTMLElement | HTMLDivElement
  previousSlideButton: HTMLButtonElement
  nextSlideButton: HTMLButtonElement
  sliderCounter: HTMLElement
  sliderNavigation: HTMLElement
  oneSliderPerNavigation?: boolean
}

class Slider {
  sliderElm: HTMLElement
  previousSlideButton: HTMLButtonElement
  nextSlideButton: HTMLButtonElement
  sliderCounter?: HTMLElement
  sliderNavigation?: HTMLElement
  nrOfSlidesPerBeakpoint: { [key: string]: number }
  oneSliderPerNavigation: boolean
  _nrOfSlides: number
  _slidePosition: number
  _totalSlides: number

  constructor(options: Props) {
    this.sliderElm = options.sliderElement
    this.previousSlideButton = options.previousSlideButton
    this.nextSlideButton = options.nextSlideButton
    this.sliderCounter = options.sliderCounter
    this.sliderNavigation = options.sliderNavigation
    this.oneSliderPerNavigation = options.oneSliderPerNavigation || false
    this._slidePosition = 0
    this._totalSlides = this.sliderElm.childElementCount

    this.setSliderCountText()

    this.previousSlideButton.addEventListener('click', () =>
      this.browseSlide(1)
    )
    this.nextSlideButton.addEventListener('click', () => this.browseSlide(-1))
  }

  setNrOfVisibleSlides(nrOf: number) {
    this._nrOfSlides = nrOf

    this.sliderElm.style.transform = `translateX(0)`
    this.sliderElm.scrollLeft = 0
    this._slidePosition = 0

    this.sliderElm.style.gridAutoColumns = `${100 / this._nrOfSlides}%`
  }

  setSliderCountText() {
    const start = Math.abs(this._slidePosition) + 1
    const endCalculated =
      Math.abs(this._slidePosition) + 1 + this._nrOfSlides - 1
    const end = 
      endCalculated > this._totalSlides ? this._totalSlides : endCalculated
    if (start === 1 && end === this._totalSlides) {
      this.sliderNavigation.style.display = 'none';
      return
    } else {
      this.sliderNavigation.style.display = 'flex';
    }
    this.sliderCounter.innerText = `${start} t/m ${end} van ${this._totalSlides}`
  }

  browseSlide(delta: number) {
    if (this._slidePosition === 0 && delta === 1) return

    if (
      this._slidePosition +
        delta * (this.oneSliderPerNavigation ? 1 : this._nrOfSlides) <
      -1 * this._totalSlides + 1
    )
      return

    this._slidePosition +=
      delta * (this.oneSliderPerNavigation ? 1 : this._nrOfSlides)

    this.sliderElm.style.transform = `translateX(${
      (this._slidePosition * 100) / this._nrOfSlides
    }%)`

    this.setSliderCountText()
  }
}

export default Slider
