interface DatePickerProps {
  element: HTMLInputElement;
  startDate: Date;
}

const MONTH_NAMES = [
  'januari',
  'februari',
  'maart',
  'april',
  'mei',
  'juni',
  'juli',
  'augustus',
  'september',
  'oktober',
  'november',
  'december',
]

const DAY_NAMES_SHORT = ['Ma', 'Di', 'Wo', 'Do', 'Vr', 'Za', 'Zo']

export const zeroPad = (value: number, length: number) => {
  return `${value}`.padStart(length, '0')
}

class DatePicker {
  element: HTMLInputElement;
  startDate: Date;
  selectedtDate: Date;
  calendar: HTMLDivElement
  currentDate: Date
  month: HTMLDivElement
  yearSelect: HTMLSelectElement
  currentMonth: number[]
  prevMonth: number[]
  nextMonth: number[]
  activeMonthGrid: HTMLDivElement

  constructor(options: DatePickerProps) {
    this.element = options.element;
    this.startDate = options.startDate || new Date();
    this.currentDate = this.startDate;
    this.currentMonth = Array.from({ length: this.getDaysInMonth() }, (_, i) => i + 1);
    this.prevMonth = this.daysInPrevMonth();
    this.nextMonth = this.daysInNextMonth();
    this.attachCalendar();
    
    this.clickOutside = this.clickOutside.bind(this);
    this.onFocus = this.onFocus.bind(this);
    this.destroy = this.destroy.bind(this);
    
  }

  onFocus() {
    this.element.addEventListener('focus', () => {
      this.currentDate = this.element.value ? new Date(this.convertDate(this.element.value)) : this.startDate;
      this.fillDaysGrid();
      document.addEventListener('click', this.clickOutside);
      this.calendar.classList.add('calendar-pannel--show');
    });
  }

  destroy() {
    document.removeEventListener('click', this.clickOutside);
  }

  clickOutside(event: MouseEvent) {
    const target = event.target as HTMLElement;
    if (this.calendar && !this.calendar.contains(target) && !target.hasAttribute('data-calendar')) {
      this.calendar.classList.remove('calendar-pannel--show');
    }
  }

  convertDate = (nldate: String) => {
    const [day, month, year] = nldate.split('-').map(Number)
    return new Date(year, month - 1, day)
  }

  getMonthFirstDay = (month: number, year: number) => {
    const dayIndex = +new Date(`${year}-${zeroPad(month, 2)}-01`).getDay()

    return dayIndex !== 0 ? dayIndex - 1 : 6
  }

  getMonthLastDay = (month: number, year: number) => {
    const dayIndex = +new Date(`${year}-${zeroPad(month, 2)}-${this.getDaysInMonth(month, year)}`).getDay()

    return dayIndex !== 0 ? dayIndex - 1 : 6
  }

  daysInPrevMonth = () => {
    const daysPreviousMonth = Array.from({ length: this.getDaysInMonth(this.currentDate.getMonth() + 1, this.currentDate.getFullYear()) }, (_, i) => i + 1)
    const firstDay = -1 * this.getMonthFirstDay(this.currentDate.getMonth() + 1, this.currentDate.getFullYear())
    return firstDay !== 0 ? daysPreviousMonth.slice(firstDay) : []
  }

  daysInNextMonth = () => {
    const restDays = 7 - this.getMonthLastDay(this.currentDate.getMonth() + 1, this.currentDate.getFullYear()) -1;
    return Array.from({ length: restDays }, (_, i) => i + 1)
  }

  getDaysInMonth = (month = this.currentDate.getMonth() + 1, year = this.currentDate.getFullYear()): number => {
    const months30 = [4, 6, 9, 11]
    const leapYear = year % 4 === 0
    return month === 2 ? (leapYear ? 29 : 28) : months30.includes(month) ? 30 : 31
  }

  createCalendar() {
    this.element.style.userSelect = 'none';
    this.calendar = document.createElement('div');
    this.calendar.classList.add('calendar-pannel');
    this.element.parentElement.appendChild(this.calendar);
    this.createHeader();
    this.calendar.appendChild(this.createHeader());
    this.calendar.appendChild(this.createDaysBar());
    this.activeMonthGrid = document.createElement('div');
    this.activeMonthGrid.classList.add('calendar-days-grid');
    this.activeMonthGrid = document.createElement('div');
    this.activeMonthGrid.classList.add('calendar-days-grid');
    this.activeMonthGrid.addEventListener('click', (event: MouseEvent) => {
      const target = event.target as HTMLElement;
      if(!target.classList.contains('calendar-day-cell')) return;
      this.element.value = `${target.innerHTML}-${this.currentDate.getMonth() + 1}-${this.currentDate.getFullYear()}`;
      this.destroy();
      this.calendar.classList.remove('calendar-pannel--show');
    });
    this.calendar.appendChild(this.activeMonthGrid);
  }

  createYearSelect() {
    const browseYearSelect = document.createElement('div');
    browseYearSelect.classList.add('calendar-year-select');
    this.yearSelect = document.createElement('select');
    const startYear = this.currentDate.getFullYear() - 90;
    const years = Array.from({ length: 150 }, (_, i) => i + startYear);
    years.forEach(year => {
      this.yearSelect.options.add(new Option(year.toString(), year.toString()));
    });
    this.yearSelect.addEventListener('change', (event) => {
      const target = event.target as HTMLSelectElement;
      this.setYear(parseInt(target.value));
    });
    browseYearSelect.appendChild(this.yearSelect);
    this.yearSelect.value = `${this.currentDate.getFullYear()}`;
    return browseYearSelect;
  }

  createHeader() {
    const header = document.createElement('div');
    header.classList.add('calendar-header');
    
    const monthWrapper = document.createElement('div');
    monthWrapper.classList.add('calendar-month');

    const browseMonthBack = document.createElement('button');
    browseMonthBack.classList.add('calendar-browse-btn');
    browseMonthBack.type = 'button';
    browseMonthBack.innerHTML = '<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="2rem" height="2rem"><path d="M8.59,16.58L13.17,12L8.59,7.41L10,6L16,12L10,18L8.59,16.58Z" fill="currentColor"/></svg>';
    browseMonthBack.addEventListener('click', () => {
      this.browseMonth(-1);
    });
    monthWrapper.appendChild(browseMonthBack);


    this.month = document.createElement('div');
    this.month.classList.add('calendar-month');
    this.month.innerHTML = `${MONTH_NAMES[this.currentDate.getMonth()]}`;
    monthWrapper.appendChild(this.month);
    
    
    const browseMonthForward = browseMonthBack.cloneNode(true) as HTMLButtonElement;
    browseMonthForward.classList.add('calendar-browse-btn--forward');
    browseMonthForward.addEventListener('click', () => {
      this.browseMonth(1);
    });
    monthWrapper.appendChild(browseMonthForward);

    header.appendChild(monthWrapper);

    const yearWrapper = document.createElement('div');
    yearWrapper.classList.add('calendar-year');
    header.appendChild(this.createYearSelect());
     return header;
  }

  createDaysBar() {
    const daysBar = document.createElement('div');
    daysBar.classList.add('calendar-days-bar');
    DAY_NAMES_SHORT.forEach(day => {
      const dayEl = document.createElement('div');
      dayEl.classList.add('calendar-day');
      dayEl.innerHTML = day;
      daysBar.appendChild(dayEl);
    });
    return daysBar;
  }

  fillDaysGrid() {
    const daysGrid = document.createElement('div');
    daysGrid.classList.add('calendar-days-grid');
    this.prevMonth.forEach(day => {
      const dayEl = document.createElement('button');
      dayEl.type = 'button';
      dayEl.disabled = true;
      dayEl.classList.add('calendar-day-cell', 'calendar-day-cell--dimmed');
      dayEl.innerHTML = day.toString();
      daysGrid.appendChild(dayEl);
    });
    this.currentMonth.forEach(day => {
      const dayEl = document.createElement('button');
      dayEl.type = 'button';
      dayEl.classList.add('calendar-day-cell');
      if(this.currentDate.getDate() === day) {
        dayEl.classList.add('calendar-day-cell--active');
      }
      dayEl.innerHTML = day.toString();
      daysGrid.appendChild(dayEl);
    });
    this.nextMonth.forEach(day => {
      const dayEl = document.createElement('button');
      dayEl.classList.add('calendar-day-cell', 'calendar-day-cell--dimmed');
      dayEl.disabled = true;
      dayEl.innerHTML = day.toString();
      daysGrid.appendChild(dayEl);
    });
    this.activeMonthGrid.innerHTML = daysGrid.innerHTML;
  }

  attachCalendar() {
    this.element.setAttribute('readonly', 'true');
    this.createCalendar();
    this.fillDaysGrid();
    this.onFocus();
  }

  browseMonth(direction: number) {
    if (this.currentDate.getMonth() === 11 && direction === 1) {
      this.currentDate.setFullYear(this.currentDate.getFullYear() + direction);
      this.currentDate.setMonth(0);
    } else if (this.currentDate.getMonth() === 0 && direction === -1) {
      this.currentDate.setFullYear(this.currentDate.getFullYear() + direction);
      this.currentDate.setMonth(11);
    } else {
      this.currentDate.setMonth(this.currentDate.getMonth() + direction);
    }
    this.prevMonth = this.daysInPrevMonth();
    this.nextMonth = this.daysInNextMonth();
    this.currentMonth = Array.from({ length: this.getDaysInMonth() }, (_, i) => i + 1);
    this.month.innerHTML = `${MONTH_NAMES[this.currentDate.getMonth()]}`;
    this.yearSelect.value = `${this.currentDate.getFullYear()}`;
    this.fillDaysGrid();
  }

  setYear(year: number) {
    this.currentDate.setFullYear(year);
    this.prevMonth = this.daysInPrevMonth();
    this.nextMonth = this.daysInNextMonth();
    this.currentMonth = Array.from({ length: this.getDaysInMonth() }, (_, i) => i + 1);
    this.fillDaysGrid();
  }
}


export default DatePicker;