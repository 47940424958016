interface AccodionProps {
  element: HTMLElement;
}

const INTERVAL = 500;
class Accordion {
  element: HTMLElement;

  constructor(options: AccodionProps) {
    this.element = options.element;
    this.attachAccordion();
    this.trigger(1, INTERVAL)
    setTimeout( () => this.trigger(2, INTERVAL), INTERVAL / 4);
  }

  trigger(index: number = 0, interval: number) {
    if(this.element.hasAttribute(`data-trigger${index}`)) {
      this.element.click();
      setTimeout( () => this.element.click(), interval * 2);
    }
  }

  attachAccordion() {
    this.element.addEventListener('click', this.toggleAccordion.bind(this));
  }

  toggleAccordion(event: MouseEvent) {
    let target = event.target as HTMLElement;
    while (target !== this.element && !target.classList.contains('accordion')) {
      target = target.parentElement as HTMLElement;
    }
    if (target.classList.contains('accordion')) {
      target.classList.toggle('accordion--active');
      const panel = target.nextElementSibling as HTMLElement;
      if (panel.style.maxHeight) {
        panel.style.maxHeight = null;
      } else {
        panel.style.maxHeight = panel.scrollHeight + "px";
      }
    }
  }
}

export default Accordion;