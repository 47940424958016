export const BREAKPOINTS = {
  XXS: 340,
  XS: 400,
  SM: 576,
  MD: 768,
  LG: 992,
  XL: 1200,
  XXL: 1400,
}

export type BreakpointState = Record<keyof typeof BREAKPOINTS, boolean>
export type Breakpoints = keyof typeof BREAKPOINTS | '_initial'
type Orientation = 'portrait' | 'landscape' | null
export type ResponsiveState = {
  biggerThan: BreakpointState
  smallerThan: BreakpointState
  orientation: Orientation
  mediaType: Breakpoints
}

const getMediaQuery = (size: number, greater: boolean): string => {
  return greater ? `(min-width: ${size}px)` : `(max-width: ${size - 1}px)`
}

const getMatches = (query: string) => {
  if (typeof window !== 'undefined') {
    return window.matchMedia(query).matches
  }
  return false
}

export const getViewportWidth = (): ResponsiveState => {
  const initialBreakpointState: BreakpointState = {
    XXS: false,
    XS: false,
    SM: false,
    MD: false,
    LG: false,
    XL: false,
    XXL: false,
  }

  const biggerThan: BreakpointState = { ...initialBreakpointState }
  const smallerThan: BreakpointState = { ...initialBreakpointState }

  Object.entries(BREAKPOINTS).forEach(([key, size]) => {
    biggerThan[key as keyof typeof BREAKPOINTS] = getMatches(
      getMediaQuery(size, true)
    )
    smallerThan[key as keyof typeof BREAKPOINTS] = getMatches(
      getMediaQuery(size, false)
    )
  })

  const orientation: Orientation = getMatches('(orientation: portrait)')
    ? 'portrait'
    : getMatches('(orientation: landscape)')
    ? 'landscape'
    : null

  const mediaType = (Object.keys(biggerThan).findLast(
    (key: keyof typeof BREAKPOINTS) =>
      biggerThan[key as keyof typeof biggerThan]
  ) || '_initial') as Breakpoints

  return {
    biggerThan,
    smallerThan,
    orientation,
    mediaType,
  }
}

export function debounce(callback: any, delay = 250) {
  let timeout: ReturnType<typeof setTimeout>

  return (...args: any) => {
    clearTimeout(timeout)
    timeout = setTimeout(() => {
      callback(...args)
    }, delay)
  }
}

export const deepClone = <T, U = T extends Array<infer V> ? V : never>(
  source: T
): T => {
  if (Array.isArray(source)) {
    return source.map((item) => deepClone(item)) as T & U[]
  }
  if (source instanceof Date) {
    return new Date(source.getTime()) as T & Date
  }
  if (source && typeof source === 'object') {
    return (Object.getOwnPropertyNames(source) as (keyof T)[]).reduce<T>(
      (o, prop) => {
        Object.defineProperty(
          o,
          prop,
          Object.getOwnPropertyDescriptor(source, prop)!
        )
        o[prop] = deepClone(source[prop])
        return o
      },
      Object.create(Object.getPrototypeOf(source))
    )
  }
  return source
}

interface enterViewpoortOptionsProps {
  root: HTMLElement | null
  threshold: number
  rootMargin: string
  imgSrc: string
}

export const enterViewport = (target: HTMLImageElement | null, options: enterViewpoortOptionsProps, isDetail: 'vertical' | 'horizontal') => {
  if (!target) return
    
  const loadImg = () => {
    const img = new Image();
    img.onload = () => {
      target.src = options.imgSrc
    }
    img.src = options.imgSrc;
  }
  
  const handleVisibilityChange = ([entry]: IntersectionObserverEntry[]) => {
    const isVisible = target.getBoundingClientRect().top < window.innerHeight;
    if (isVisible && isDetail === 'vertical') {
      loadImg();
    }

    if (entry.isIntersecting) {
      loadImg();
      observer.unobserve(target)
    }
  }

  const observer = new IntersectionObserver(handleVisibilityChange, options)
  observer.observe(target)

}

export const preloadImage = (): string => {
  return 'data:image/svg+xml;charset=utf-8,<svg version="1.1" id="L9" xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" viewBox="-250 -250 600 600"><path d="M27,50.1c0,12.7,10.3,23,23,23s23-10.3,23-23 M69.1,50.1c0,10.5-8.5,19.1-19.1,19.1s-19.1-8.6-19.1-19.1" fill="%23FFFFFF" opacity="0.3"><animateTransform attributeName="transform" attributeType="XML" type="rotate" dur="1s" from="0 50 50" to="360 50 50" repeatCount="indefinite"/></path></svg>';
}